import React, { useState, useEffect } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import Person from "../assets/storefrontGrey.svg";
import editImg from "../assets/edit_square.png";
import Select from "react-select";
import DropDwn from "../assets/drop-dwn.png";
import {
  AddStaffPageText,
  create_restaurants,
} from "./../CommonJquery/WebsiteText";
import {
  handleEmailChange,
  handleNumbersChange,
  handleAphabetsChange,
  handleError,
  handlePasswordChange,
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  empty_form,
  handleIaphabetnumberChange,
  cencelChanges,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_new_resturant,
  get_all_new_resturant,
  get_all_Landmark_data,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { useLocation } from "react-router-dom";
function CreateRestro() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [designations, setdesignations] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [category, setCategory] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [permanentAddress, setPermanentAddress] = useState("");
  const [temporaryAddress, setTemporaryAddress] = useState("");
  const [copyAddress, setCopyAddress] = useState(false);
  const [categorySelected, setCategorySelected] = useState([]);
  const [amenitiesSelected, setAmenitiesSelected] = useState([]);
  const [cuisineSelected, setCuisineSelected] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [getLandmark, setLandmark] = useState([]);

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size < 200 * 1024) {
        // 200KB in bytes
        alert("File size is below the minimum limit (200KB).");
        return;
      }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        alert("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      let temp_array = [];
      let temp_array2 = [];
      let temp_array3 = [];
      categorySelected.forEach((element) => {
        temp_array.push(element.value);
      });
      amenitiesSelected.forEach((element) => {
        temp_array2.push(element.value);
      });
      cuisineSelected.forEach((element) => {
        temp_array3.push(element.value);
      });
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("category", temp_array);
      fd_from.append("amenities", temp_array2);
      fd_from.append("cuisine", temp_array3);
      fd_from.append("restype", selectedType);
      fd_from.append("old_image_link", editOldImageData);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/View_Restaurants");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    const input = document.getElementById("searchInput");
    const autocomplete = new window.google.maps.places.Autocomplete(input);

    autocomplete.addListener("place_changed", function () {
      const place = autocomplete.getPlace();
      let full_address = place.address_components;
      let formatted_address = place.formatted_address;
      let length_data = place.address_components.length;
      let citys = "";
      let state = "";
      let country = "";
      let tehsil = "";
      for (let i = 0; i < length_data; i++) {
        if (full_address[i].types[0] === "administrative_area_level_1") {
          state = full_address[i].long_name;
        } else if (full_address[i].types[0] === "country") {
          country = full_address[i].long_name;
        } else if (full_address[i].types[0] === "administrative_area_level_2") {
          citys = full_address[i].long_name;
        } else if (full_address[i].types[0] === "locality") {
          tehsil = full_address[i].long_name;
        }
      }
      if (tehsil !== "") {
        citys = tehsil;
      }
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      setPermanentAddress(formatted_address);
      document.getElementById("restaurant_city").value = citys;
      document.getElementById("restaurant_state").value = state;
      document.getElementById("restaurant_country").value = country;
      document.getElementById("lat").value = latitude;
      document.getElementById("lng").value = longitude;
      master_data_get_landmark(citys, country);
    });
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_new_resturant, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_restaurant.length > 0) {
            seteditStaffData(Response.data.message.data_restaurant[0]);

            setEditorDatMainID(
              Response.data.message.data_restaurant[0].primary_id
            );
            seteditOldImageData(
              Response.data.message.data_restaurant[0].restaurant_image
            );
            setPermanentAddress(
              Response.data.message.data_restaurant[0].restaurant_full_adrress
            );
            setSelectedType(
              parseInt(
                Response.data.message.data_restaurant[0].veg_non_both_type
              )
            );
            setDynaicimage({
              event_list_image_show:
                APL_LINK +
                Response.data.message.data_restaurant_image +
                Response.data.message.data_restaurant[0].restaurant_image,
            });
            setTemporaryAddress(
              Response.data.message.data_restaurant[0]
                .restaurant_temorary_adrress
            );
            master_data_get_landmark(
              Response.data.message.data_restaurant[0].restaurant_city,
              Response.data.message.data_restaurant[0].restaurant_country
            );

            const options_cat = Response.data.message.categorySelected.map(
              (option) => ({
                value: `${option.category_id}~@~${option.category_name}`,
                label: option.category_name,
              })
            );
            setCategorySelected(options_cat);
            const options_ami = Response.data.message.amenitiesselected.map(
              (option) => ({
                value: `${option.amenities_id}~@~${option.amenities_name}~@~${option.image}`,
                label: option.amenities_name,
              })
            );

            setAmenitiesSelected(options_ami);
            const options_cuisi = Response.data.message.cuisineselected.map(
              (option) => ({
                value: `${option.cuisine_id}~@~${option.cuisine_name}`,
                label: option.cuisine_name,
              })
            );
            setCuisineSelected(options_cuisi);
          } else {
            setSelectedType(0);
          }
          setdesignations(Response.data.message.data_designation);
          setCuisines(
            Response.data.message.cuisine.map((option) => ({
              value: `${option.primary_id}~@~${option.cuisine_name}`,
              label: option.cuisine_name,
            }))
          );
          setCategory(
            Response.data.message.category.map((option) => ({
              value: `${option.primary_id}~@~${option.category_master_name}`,
              label: option.category_master_name,
            }))
          );
          setAmenities(
            Response.data.message.amenities.map((option) => ({
              value: `${option.primary_id}~@~${option.amenities_name}~@~${option.image}`,
              label: option.amenities_name,
            }))
          );
        }
        // setLandmark(Response.data.message.Landmark);
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_get_landmark = async (city, country) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("city", city);
    fd.append("country", country);
    await server_post_data(get_all_Landmark_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setLandmark(Response.data.message.Landmark);
          setshowLoaderAdmin(false);
        }
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleCheckboxChange2 = () => {
    setCopyAddress(!copyAddress); // Toggle checkbox state
    if (!copyAddress) {
      setTemporaryAddress(permanentAddress); // Copy permanent address to temporary address
    } else {
      setTemporaryAddress(""); // Clear temporary address
    }
  };

  const handleChange1 = (selectedOption) => {
    setCategorySelected(selectedOption);
  };
  const handleChange2 = (selectedOption) => {
    setAmenitiesSelected(selectedOption);
  };
  const handleChange3 = (selectedOption) => {
    setCuisineSelected(selectedOption);
  };

  const handleInputChangetype = (e) => {
    const value = e.target.value;
    setSelectedType(value);
  };
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                {location.pathname.includes("edit_restaurants") ? (
                  <p>Edit Restaurant Details</p>
                ) : (
                  <p>Add Restaurant Details</p>
                )}
                <img src={GreyArrow} alt="Dfoodo Dashboard" />
              </div>
            </div>

            <div className="page_body">
              <div className="addStaffForms">
                <form id="addNewStaff">
                  <div className="row m-0 justify_center">
                    <div className="col-xl-6 col-lg-7">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0">
                            <div className="col-lg-3 col-md-4 mx-auto">
                              <div
                                className={
                                  "inpContainer imginputContainer flex-column "
                                }
                              >
                                <div
                                  className={
                                    dynaicimage ? "" : " inputDiv1 imgInput"
                                  }
                                >
                                  <label
                                    htmlFor="event_list_image"
                                    className="imgInptImg"
                                  >
                                    {dynaicimage &&
                                    dynaicimage.event_list_image_show ? (
                                      <div
                                        className="imgDiv"
                                        htmlFor="event_list_image"
                                      >
                                        <img
                                          src={
                                            dynaicimage.event_list_image_show
                                          }
                                          onError={(e) =>
                                            (e.target.src = Person)
                                          }
                                          alt="Preview"
                                        />
                                        <img
                                          className="profileImgEdit"
                                          src={editImg}
                                          alt="Dfoodo Dashboard"
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        <img
                                          style={{ width: "1.5rem" }}
                                          src={Person}
                                          alt="Dfoodo Dashboard"
                                        />
                                        <p className="m-0">
                                          {AddStaffPageText.Add_Image}
                                        </p>
                                        <div className="imgValidationalert ">
                                          <p>
                                            {AddStaffPageText.Image_Validation}
                                          </p>
                                          <p>
                                            {AddStaffPageText.Image_Validation2}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </label>
                                </div>
                                <input
                                  name="event_list_image"
                                  id="event_list_image"
                                  type="file"
                                  onChange={handleFileChangedynamic(
                                    "event_list_image"
                                  )}
                                  className={`form-control ${
                                    dynaicimage &&
                                    dynaicimage.event_list_image_show
                                      ? ""
                                      : "trio_mandatory"
                                  }`}
                                  accept=".jpg,.jpeg,.png"
                                  hidden
                                />
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="addstaffInputs">
                                <div className="inpContainer">
                                  <div className="resturant_icon image_icon_position image_icon_position1">
                                    <input
                                      type="text"
                                      id="restaurant_name"
                                      name="restaurant_name"
                                      tabIndex="1"
                                      placeholder={
                                        create_restaurants.Restaurant_name
                                      }
                                      minLength={3}
                                      maxLength={75}
                                      className="trio_name trio_mandatory form-control  input_field_custom1 "
                                      // onInput={handleAphabetsChange}
                                      defaultValue={
                                        editStaffData.restaurant_name || ""
                                      }
                                    />
                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                                <div className="inpContainer">
                                  <div className="tagline_icon image_icon_position image_icon_position1">
                                    <input
                                      type="text"
                                      id="restaurant_tagline"
                                      name="restaurant_tagline"
                                      tabIndex="1"
                                      placeholder={
                                        create_restaurants.Restaurant_tag_line
                                      }
                                      minLength={3}
                                      maxLength={75}
                                      className="trio_name trio_mandatory form-control  input_field_custom1 "
                                      onInput={handleIaphabetnumberChange}
                                      defaultValue={
                                        editStaffData.restaurant_tagline || ""
                                      }
                                    />
                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row m-0">
                            <div className="col-md-6">
                              <div className="inpContainer">
                                <div className="person__phone_image image_icon_position1">
                                  <input
                                    type="text"
                                    name="restaurant_mobile_no"
                                    placeholder={AddStaffPageText.Phone_Number}
                                    minLength={4}
                                    maxLength={10}
                                    className="trio_no trio_mandatory form-control input_field_custom2 "
                                    onInput={(e) => handleNumbersChange(e)}
                                    defaultValue={
                                      editStaffData.restaurant_mobile_no || ""
                                    }
                                  />
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="inpContainer">
                                <div className="person__phone_image image_icon_position1">
                                  <input
                                    type="text"
                                    id="restaurant_other_no"
                                    name="restaurant_other_no"
                                    placeholder={
                                      AddStaffPageText.Alt_Phone_Number
                                    }
                                    maxLength={10}
                                    minLength={4}
                                    className="trio_no  form-control input_field_custom2 "
                                    onInput={(e) => handleNumbersChange(e)}
                                    defaultValue={
                                      editStaffData.restaurant_other_no || ""
                                    }
                                  />
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="inpContainer">
                                <div className="person__email_image image_icon_position1 mt-2">
                                  <input
                                    type="text"
                                    name="restaurant_email"
                                    placeholder={"Email Id*"}
                                    minLength={4}
                                    maxLength={72}
                                    className="trio_email trio_mandatory form-control  input_field_custom1 "
                                    onInput={(e) => handleEmailChange(e)}
                                    defaultValue={
                                      editStaffData.restaurant_email || ""
                                    }
                                  />
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="inpContainer ">
                                <div className="image_icon_position1">
                                  <label className="no_prsnl_id">
                                    {AddStaffPageText.Permanent_Address}
                                  </label>
                                  <textarea
                                    type="text"
                                    rows="7"
                                    id="searchInput"
                                    name="restaurant_full_adrress"
                                    maxLength={250}
                                    minLength={5}
                                    placeholder={
                                      AddStaffPageText.Permanent_addressplaceholder
                                    }
                                    value={permanentAddress}
                                    onChange={(e) =>
                                      setPermanentAddress(e.target.value)
                                    }
                                    className="trio_mandatory form-control input_field_custom3"
                                    defaultValue={
                                      editStaffData.restaurant_full_adrress ||
                                      ""
                                    }
                                  ></textarea>
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className="form-row hidden">
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationCustom01">City</label>
                                <input
                                  type="text"
                                  className="form-control  searchInput_google"
                                  name="restaurant_city"
                                  id="restaurant_city"
                                  maxLength={100}
                                  minLength={3}
                                  onInput={handleAphabetsChange}
                                  placeholder={AddStaffPageText.City_Name}
                                  defaultValue={
                                    editStaffData.restaurant_city || ""
                                  }
                                />
                                <span className="condition_error"></span>
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationCustom01">
                                  {AddStaffPageText.State}
                                </label>
                                <input
                                  type="text"
                                  className="form-control  "
                                  name="restaurant_state"
                                  id="restaurant_state"
                                  maxLength={100}
                                  onInput={handleAphabetsChange}
                                  placeholder={AddStaffPageText.Enter_State}
                                  defaultValue={
                                    editStaffData.restaurant_state || ""
                                  }
                                />
                                <span className="condition_error"></span>
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationCustom01">
                                  {AddStaffPageText.State}
                                </label>
                                <input
                                  type="text"
                                  className="form-control  "
                                  name="lat"
                                  id="lat"
                                  maxLength={100}
                                  onInput={handleAphabetsChange}
                                  placeholder={AddStaffPageText.Enter_State}
                                  defaultValue={
                                    editStaffData.restaurant_lat || ""
                                  }
                                />
                                <span className="condition_error"></span>
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationCustom01">
                                  {AddStaffPageText.State}
                                </label>
                                <input
                                  type="text"
                                  className="form-control  "
                                  name="lng"
                                  id="lng"
                                  maxLength={100}
                                  onInput={handleAphabetsChange}
                                  placeholder={AddStaffPageText.Enter_State}
                                  defaultValue={
                                    editStaffData.restaurant_lng || ""
                                  }
                                />
                                <span className="condition_error"></span>
                              </div>
                              <div className="col-md-4 mb-3">
                                <label htmlFor="validationCustom01">
                                  {AddStaffPageText.Country}
                                </label>
                                <input
                                  type="text"
                                  className="form-control  "
                                  name="restaurant_country"
                                  id="restaurant_country"
                                  maxLength={100}
                                  onInput={handleAphabetsChange}
                                  placeholder={AddStaffPageText.Enter_Country}
                                  defaultValue={
                                    editStaffData.restaurant_country || ""
                                  }
                                />
                                <span className="condition_error"></span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="inpContainer">
                                <div className="no_prsnl_id">
                                  <div className="rememberMe">
                                    <input
                                      type="checkbox"
                                      id="adress_box"
                                      tabIndex="2"
                                      className="hidden-checkbox"
                                      onChange={handleCheckboxChange2}
                                    />

                                    <label
                                      htmlFor="adress_box"
                                      className="checkbox-label labelCheckbox"
                                      tabIndex="2"
                                    >
                                      {AddStaffPageText.Same_Address}
                                    </label>
                                  </div>
                                </div>

                                <div className="image_icon_position1">
                                  <label className="no_prsnl_id">
                                    {AddStaffPageText.Temporary_Address}
                                  </label>
                                  <textarea
                                    type="text"
                                    id="searchInput2"
                                    rows="4"
                                    name="restaurant_temorary_adrress"
                                    maxLength={250}
                                    minLength={5}
                                    value={temporaryAddress}
                                    placeholder={
                                      AddStaffPageText.Permanent_addressplaceholder
                                    }
                                    onChange={(e) =>
                                      setTemporaryAddress(e.target.value)
                                    }
                                    className="form-control input_field_custom3 "
                                    defaultValue={
                                      editStaffData.restaurant_temorary_adrress ||
                                      ""
                                    }
                                  ></textarea>
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            {editorDataMainID === "0" && (
                              <div className="socialdetails row m-0 p-0">
                                <div className="col-sm-6">
                                  <div className="inpContainer">
                                    <label className="no_prsnl_id">
                                      {AddStaffPageText.Create_pass}
                                    </label>

                                    <div className="password_image image_icon_position1">
                                      <input
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        name="admin_password"
                                        id="admin_password"
                                        placeholder={
                                          AddStaffPageText.password_text
                                        }
                                        maxLength={40}
                                        minLength={3}
                                        className="trio_password trio_mandatory form-control input_field_custom1 new_password"
                                        onInput={(e) => handlePasswordChange(e)}
                                        onPaste={(e) => e.preventDefault()}
                                        onCopy={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onDrag={(e) => e.preventDefault()}
                                        onDrop={(e) => e.preventDefault()}
                                      />
                                      <span
                                        className="eye_icon1"
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                      >
                                        {showPassword ? (
                                          <div className="eye_open"></div>
                                        ) : (
                                          <div className="eye_close"></div>
                                        )}
                                      </span>
                                      <span className="condition_error"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  {" "}
                                  <div className="inpContainer">
                                    <label className="no_prsnl_id">
                                      {AddStaffPageText.Confirm_pass}
                                    </label>

                                    <div className="password_image image_icon_position1">
                                      <input
                                        type={
                                          showPassword1 ? "text" : "password"
                                        }
                                        name="admin_confirm_password"
                                        id="admin_confirm_password"
                                        placeholder={
                                          AddStaffPageText.password_text2
                                        }
                                        maxLength={40}
                                        minLength={3}
                                        className="trio_password trio_mandatory form-control input_field_custom1 confirm_new_password"
                                        onInput={(e) => handlePasswordChange(e)}
                                        onPaste={(e) => e.preventDefault()}
                                        onCopy={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onDrag={(e) => e.preventDefault()}
                                        onDrop={(e) => e.preventDefault()}
                                      />
                                      <span
                                        className="eye_icon1"
                                        onClick={() =>
                                          setShowPassword1(!showPassword1)
                                        }
                                      >
                                        {showPassword1 ? (
                                          <div className="eye_open"></div>
                                        ) : (
                                          <div className="eye_close"></div>
                                        )}
                                      </span>
                                      <span className="condition_error"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-5">
                      {editorDataMainID === "0" && (
                        <div className="col-xl-12">
                          <div className="employeForm">
                            <div className="staffForm_container">
                              <h5
                                className="mb-4"
                                style={{
                                  paddingLeft: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                {create_restaurants.conatct_person_details}
                              </h5>
                              <div className="row m-0">
                                <div className="col-xl-8 col-lg-12 col-md-6">
                                  <div className="inpContainer">
                                    <div className="person_image image_icon_position image_icon_position1">
                                      <input
                                        type="text"
                                        id="admin_name"
                                        name="admin_name"
                                        tabIndex="1"
                                        placeholder={
                                          AddStaffPageText.Placeholder_Name
                                        }
                                        minLength={3}
                                        maxLength={75}
                                        className="trio_name trio_mandatory form-control  input_field_custom1 "
                                        onInput={handleAphabetsChange}
                                      />
                                      <span className="condition_error"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-8 col-lg-12 col-md-6">
                                  <div className="inpContainer">
                                    <div className="person__phone_image image_icon_position1">
                                      <input
                                        type="text"
                                        name="admin_mobile_no"
                                        placeholder={
                                          AddStaffPageText.Phone_Number
                                        }
                                        minLength={4}
                                        maxLength={10}
                                        className="trio_no trio_mandatory form-control input_field_custom2 "
                                        onInput={(e) => handleNumbersChange(e)}
                                      />
                                      <span className="condition_error"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-8 col-lg-12 col-md-6">
                                  <div className="inpContainer">
                                    <div className="person__email_image image_icon_position1 mt-2">
                                      <input
                                        type="text"
                                        name="admin_email"
                                        placeholder={AddStaffPageText.Add_Email}
                                        minLength={4}
                                        maxLength={72}
                                        className="trio_email trio_mandatory form-control  input_field_custom1 "
                                        onInput={(e) => handleEmailChange(e)}
                                      />
                                      <span className="condition_error"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-8 col-lg-12 col-md-6">
                                  <div className="inpContainer curser_Pointer">
                                    <div className="person__business_center_image image_icon_position1">
                                      <div className="dropDownn">
                                        <img src={DropDwn} alt="img"></img>
                                      </div>
                                      <select
                                        id="select_designation"
                                        name="admin_designation"
                                        className="trio_mandatory form-control input_field_custom1"
                                      >
                                        <option value="" disabled hidden>
                                          {AddStaffPageText.Select_Designation}
                                        </option>
                                        {designations.map((option, index) => (
                                          <option
                                            key={index}
                                            value={option.primary_id}
                                          >
                                            {option.designation_name}
                                          </option>
                                        ))}
                                      </select>
                                      <span className="condition_error"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-xl-12 mt-4">
                        <div className="employeForm">
                          <div className="staffForm_container">
                            <h5
                              className="mb-4"
                              style={{
                                paddingLeft: "12px",
                                fontWeight: "400",
                              }}
                            >
                              Select Restaurant Features
                            </h5>

                            <div className="row m-0">
                              <div className="col-xl-8 col-lg-12  col-md-6">
                                <div className="inpContainer curser_Pointer">
                                  <div className="person__business_center_image image_icon_position1">
                                    <div className="dropDownn">
                                      <img src={DropDwn} alt="img"></img>
                                    </div>
                                    <select
                                      id="select_designation1"
                                      name="admin_Landmark"
                                      // placeholder="select Area"
                                      className="trio_mandatory form-control input_field_custom1"
                                      style={{ zIndex: "0" }}
                                    >
                                      <option value="">Select Area</option>
                                      {getLandmark &&
                                        getLandmark.map((option) => (
                                          <option
                                            key={option.primary_id}
                                            value={option.primary_id}
                                            selected={
                                              (editStaffData.landmark_id ||
                                                "") == option.primary_id
                                            }
                                          >
                                            {option.landmark_map_name}
                                          </option>
                                        ))}
                                    </select>
                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-8 col-lg-12 col-md-6">
                                <Select
                                  className=" input_field_custom1_add_restro form-control "
                                  placeholder="Select category"
                                  options={category}
                                  value={categorySelected}
                                  onChange={handleChange1}
                                  isSearchable
                                  isMulti
                                />

                                <span className="condition_error"></span>
                              </div>
                              <div className="col-xl-8 col-lg-12 col-md-6">
                                <Select
                                  className=" input_field_custom1_add_restro"
                                  placeholder="Select Amenities"
                                  options={amenities}
                                  value={amenitiesSelected}
                                  onChange={handleChange2}
                                  isSearchable
                                  isMulti
                                />
                                <span className="condition_error"></span>
                              </div>
                              <div className="col-xl-8 col-lg-12 col-md-6 indexSelect">
                                <Select
                                  className="input_field_custom1_add_restro"
                                  placeholder="Select Cuisines"
                                  isMulti
                                  isSearchable
                                  options={cuisines}
                                  value={cuisineSelected}
                                  onChange={handleChange3}
                                />
                                <span className="condition_error"></span>
                              </div>
                              <div className="col-xl-8 col-lg-12 col-md-6">
                                <input
                                  type="text"
                                  id="restaurant_price"
                                  name="restaurant_price"
                                  placeholder={
                                    AddStaffPageText.restaurant_price
                                  }
                                  maxLength={10}
                                  minLength={3}
                                  className="  form-control input_field_custom2 "
                                  onInput={(e) => handleNumbersChange(e)}
                                  defaultValue={
                                    editStaffData.restaurant_price || ""
                                  }
                                  style={{ paddingLeft: "0.8rem" }}
                                />
                                <span className="condition_error"></span>
                              </div>
                            </div>
                            <div>
                              {selectedType !== null && (
                                <div className="mt-2 mx-3 px-2">
                                  <label>
                                    Restaurant Type:
                                    <label>
                                      <input
                                        className=" mx-1"
                                        type="radio"
                                        name="type"
                                        value="0"
                                        defaultChecked={selectedType === 0}
                                        onChange={handleInputChangetype}
                                        required
                                      />
                                      Veg
                                    </label>
                                    <label>
                                      <input
                                        className=" mx-1"
                                        type="radio"
                                        name="type"
                                        value="1"
                                        defaultChecked={selectedType === 1}
                                        // defaultValue={editStaffData.veg_non_both_type}
                                        onChange={handleInputChangetype}
                                        required
                                      />
                                      Non-Veg
                                    </label>
                                    <label>
                                      <input
                                        className=" mx-1"
                                        type="radio"
                                        name="type"
                                        value="2"
                                        defaultChecked={selectedType === 2}
                                        onChange={handleInputChangetype}
                                        required
                                      />
                                      Both
                                    </label>
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="saveFormBtns">
                    <button
                      className="btnCancel"
                      type="button"
                      onClick={cencelChanges}
                    >
                      {AddStaffPageText.cencel_text}
                    </button>

                    <button
                      className="btnSave"
                      type="button"
                      onClick={() =>
                        handleSaveChangesdynamic(
                          "addNewStaff",
                          save_new_resturant
                        )
                      }
                    >
                      {location.pathname.includes("/edit_restaurants")
                        ? AddStaffPageText.update_text
                        : AddStaffPageText.save_text}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateRestro;
